.active-button {
  text-decoration: underline;
}

.firebaseui-container {
  form {
    flex-direction: column;
  }
}

.hover-primary-secondary {
  background-color: $color-primary;
  text-decoration-color: $color-white;

  div {
    color: $color-white;
  }

  &:hover {
    background-color: $color-secondary;
    cursor: pointer;
  }
}

.hover-mix-secondary {
  background-color: $color-mix-primary-secondary;
  text-decoration-color: $color-white;

  div {
    color: $color-white;
  }

  &:hover {
    background-color: $color-secondary;
    cursor: pointer;
  }
}

.hover-secondary-mix {
  background-color: $color-secondary;
  text-decoration-color: $color-white;

  div {
    color: $color-white;
  }

  &:hover {
    background-color: $color-mix-primary-secondary;
    cursor: pointer;
  }
}

.hover-white-secondary {
  background-color: $color-white;
  text-decoration-color: $color-black;

  div {
    color: $color-black;
  }

  &:hover {
    background-color: $color-secondary;
    text-decoration-color: $color-white;
    cursor: pointer;

    div {
      color: $color-white;
    }
  }
}

.placeholder-list, .placeholder-table {
  margin: 25px;

  .placeholder-list-row, .placeholder-table-row {
    display: flex;

    .text-row {
      margin: 0 0.25em;
    }
  }
}
