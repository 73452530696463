.bylaws {
  $list-indent: 30px;
  $margin-bottom: 5px;

  ol {
    list-style-type: decimal;

    ol {
      list-style-type: lower-roman;

      ol {
        list-style-type: decimal;
      }

      ul {
        list-style-type: lower-alpha;
      }
    }
  }

  ol, ul {
    margin-left: $list-indent;
    margin-bottom: $margin-bottom;
  }

  li {
    margin-bottom: calc($margin-bottom / 2);
  }

  table {
    margin-left: auto;
    margin-right: auto;
  }
}

.bylaws-status {
  margin-top: 20px;
}
