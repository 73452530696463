header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: $header-total-height;

  background-color: $color-primary;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.5);

  @mixin menu-layout {
    display: flex;
    width: 100%;

    background-color: $color-primary;

    @include breakpoint(xl) {
      padding-left: map-get(map-get($breakpoints, xl), wide-padding);
      padding-right: map-get(map-get($breakpoints, xl), wide-padding);
    }
    @include breakpoint(xxl) {
      padding-left: map-get(map-get($breakpoints, xxl), wide-padding);
      padding-right: map-get(map-get($breakpoints, xxl), wide-padding);
    }
    @include breakpoint(xxxl) {
      padding-left: map-get(map-get($breakpoints, xxxl), wide-padding);
      padding-right: map-get(map-get($breakpoints, xxxl), wide-padding);
    }

    ul {
      display: flex;

      > li {
        display: flex;

        > div {
          margin: auto 15px;
        }
      }
    }
  }

  @mixin popup-menu-layout {
    position: fixed;
    margin-top: 0;
    z-index: 1;

    display: flex;
    flex-direction: column;

    box-shadow: 0 0 3px 3px $color-primary;

    li {
      justify-content: center;
      padding: 16px 0;
    }
  }

  @mixin hide-under-small {
    visibility: hidden;
    width: 0;

    @include breakpoint(sm) {
      visibility: unset;
      width: auto;
    }
  }

  @mixin show-above-small {
    visibility: visible;
    width: auto;

    @include breakpoint(sm) {
      visibility: hidden;
      width: 0;
    }
  }

  #menu-menu {
    display: flex;
    height: $header-height;

    #menu-main {
      @include menu-layout;
      justify-content: flex-start;
    }

    #menu-profile {
      @include menu-layout;
      justify-content: flex-end;

      .profile-container {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;

        .profile-image {
          font-size: $header-icon-size;
          margin-right: 5px;
          color: $color-white;
        }

        .profile-name {
          @include hide-under-small;
          margin: 0 0 0 5px;
        }
      }

      .menu-profile-popup {
        @include popup-menu-layout;
        top: $header-height;

        right: 0;
        @include breakpoint(xl) {
          right: map-get(map-get($breakpoints, xl), wide-padding);
        }
        @include breakpoint(xxl) {
          right: map-get(map-get($breakpoints, xxl), wide-padding);
        }
        @include breakpoint(xxxl) {
          right: map-get(map-get($breakpoints, xxxl), wide-padding);
        }

        width: 100%;
        @include breakpoint(sm) {
          width: $min-width;
        }
      }
    }
  }

  #menu-submenu-nav {
    @include menu-layout;
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: $header-submenu-height;

    background-color: $color-white;

    .submenu-full {
      @include hide-under-small;
    }

    .submenu-small {
      @include show-above-small;
    }

    .menu-submenu-popup {
      @include popup-menu-layout;
      top: $header-total-height;
      left: 0;

      width: 100%;
      @include breakpoint(sm) {
        width: $min-width;
      }
    }
  }

  a, div {
    @include header-text-style;
  }
}
