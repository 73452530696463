// From: https://adrianmanduc.medium.com/how-to-create-a-scss-mixin-for-responsive-design-8aad4f70f1d5

$_wide-padding: 100px;

$breakpoints: (
        min: (
                min: $min-width,
                max: $min-width - 0.02px,
                wide-padding: 0,
        ),
        sm: (
                min: 576px,
                max: 575.98px,
                wide-padding: 0,
        ),
        md: (
                min: 768px,
                max: 767.98px,
                wide-padding: 0,
        ),
        lg: (
                min: 992px,
                max: 991.98px,
                wide-padding: 0,
        ),
        xl: (
                min: 1200px,
                max: 1199.98px,
                wide-padding: $_wide-padding,
        ),
        xxl: (
                min: 1400px,
                max: 1399.98px,
                wide-padding: 2 * $_wide-padding,
        ),
        xxxl: (
                min: 1600px,
                max: 1599.98px,
                wide-padding: 3 * $_wide-padding,
        )
);

@mixin breakpoint($breakpoint, $direction: min) {

  //check if the supplied breakpoint exists in our breakpoints map
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-values: map-get($breakpoints, $breakpoint);
    $breakpoint-min: map-get($breakpoint-values, min);
    $breakpoint-max: map-get($breakpoint-values, max);

    //check if we are writing styles for larger or smaller screens
    @if $direction == min {
      @media (min-width: $breakpoint-min) {
        @content;
      }
    }

    @else {
      @media (max-width: $breakpoint-max) {
        @content;
      }
    }

    // use the custom value if the breakpoint is not part of the pre-defined list
  } @else {

    @if $direction == min {
      @media (min-width: $breakpoint) {
        @content;
      }
    }

    @else {
      @media (max-width: $breakpoint) {
        @content;
      }
    }
  }
}
