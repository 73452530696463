
// COLORS

$color-black: #333333;
$color-grey: #cccccc;
$color-white: #ffffff;
$color-grey-light: mix($color-grey, $color-white);

$color-primary: #2c2c2c;
$color-secondary: #123456;
$color-mix-primary-secondary: mix($color-primary, $color-secondary);

$color-background: #eeeeee;
$color-content-background: $color-white;

$color-link: #0077aa;
$color-link-visited: #0077aa;

// LAYOUT

$min-width: 300px;

$header-height: 60px;
$header-button-height: 60px;
$header-icon-size: 30px;
$header-submenu-height: 50px;
$header-total-height: $header-height + $header-submenu-height;

$submenu-height: 50px;

$content-width: 960px;

$footer-font-size: 14px;
$footer-height: 4 * $footer-font-size;

// STYLES

@mixin h3 {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;

  margin-top: 0.5em;
  margin-bottom: 0.25em;
}

@mixin header-text-style {
  color: $color-white;
  font-size: 16px;

  &:visited {
    color: $color-white;
  }

  text-transform: uppercase;
  text-decoration: none;
}

@mixin footer-text-style {
  color: $color-white;
  font-size: 14px;

  &:visited {
    color: $color-white;
  }
}

@mixin footer-text-style-dark {
  color: $color-black;
  font-size: 14px;

  &:visited {
    color: $color-black;
  }
}
