#main-content {
  display: flex;
  flex-direction: column;

  padding: 10px 10px 40px;
  @include breakpoint(lg) {
    padding: 10px 20px 40px;
  }

  .content-menu {
    display: flex;
    flex-direction: column;

    > li {
      display: flex;
      flex-direction: column;

      margin: 3px 0;

      border: 2px solid $color-secondary;

      > div {
        margin: 20px auto;

        text-transform: uppercase;
      }
    }
  }

  form {
    width: 100%;
    display: flex;
    justify-content: center;

    > * {
      //width: 100%;
    }
  }

  table {
    border-collapse: collapse;

    caption {
      @include h3;
    }

    @mixin col-cell {
      background-color: $color-grey-light;
      text-align: center;
    }

    .col-cell {
      @include col-cell;
    }

    .col-cell-rank {
      @include col-cell;
      width: 0;
    }

    .right-cell {
      text-align: right;
    }

    .sorted-any {
      &:after {
        content: '🠓';
        color: $color-grey-light;
      }
    }

    .sorted-asc {
      &:after {
        content: '🠑';
      }
    }

    .sorted-desc {
      &:after {
        content: '🠓';
      }
    }

    .sorted-none {
      &:after {
        content: '🠓';
        color: $color-grey-light;
      }
    }

    th {
      background-color: $color-grey-light;
      font-weight: bold;
      cursor: default;
    }

    th, td {
      border: 1px solid;
      padding: 2px 3px 2px 3px;
      text-align: center;

      font-size: 14px;
    }
  }
}
