
// BASE STYLES

a {
  color: $color-link;

  &:visited {
    color: $color-link-visited;
  }
}

h1 {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

  margin-top: 0.5em;
  margin-bottom: 0.25em;

  &:after {
    content: ' ';
    display: block;
    border: 2px solid $color-grey;
    border-radius:4px;
  }
}

h2 {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

  margin-top: 0.5em;
  margin-bottom: 0.25em;

  &:after {
    content: ' ';
    display: block;
    border: 1px solid $color-grey;
    border-radius: 4px;
  }
}

h3 {
  @include h3;
  text-align: center;
}

h4 {
  font-size: 16px;
  font-weight: bold;
}

ul {
  list-style-type: none;
}

// LAYOUT

body {
  min-width: $min-width;
  background-color: $color-background;
}

main {
  padding-top: $header-total-height;
  margin: auto;
  min-height: calc(100vh - $header-total-height - $footer-height);
  min-width: $min-width;

  display: flex;
  flex-direction: column;

  width: 100%;
  @include breakpoint(lg) {
    width: $content-width;
  }

  background-color: $color-content-background;
}
