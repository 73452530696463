footer {
  display: flex;
  height: $footer-height;
  min-width: $min-width;
  margin: 0 auto;

  width: 100%;
  @include breakpoint(lg) {
    width: $content-width;
  }

  flex-direction: column;
  justify-content: center;

  background-color: $color-primary;

  > div {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-wrap: wrap;

    .legal-row-item {
      @include footer-text-style;
      margin: auto 2.5px;
    }

    .version-row-item {
      @include footer-text-style-dark;
      margin: auto 2.5px;
    }
  }
}
